//
// Daterange
//

// ngb-datepicker {
.ngb-dp-month-name {
    font-size: 15px;
}
.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-flex;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    font-size: 12px;
    color: #{map-get($grays, "700")};
    box-shadow: none;

    &:hover {
        background-color: $primary !important;
        color: $white;
    }

    &.range {
        background-color: $primary !important;
        color: $white;
    }

    &.range.faded {
        background-color: #{lighten(map-get($grays, "300"), 5%)} !important;
        color: #{map-get($grays, "700")} !important;
    }
}
// }
// Dark mode
body[data-layout-color="dark"] {
    .custom-day {
        color: #{map-get($dark-grays, "700")};
    }
    &.range.faded {
        background-color: #{map-get($dark-grays, "300")} !important;
        color: #{map-get($dark-grays, "700")} !important;
    }
}
