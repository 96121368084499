//
// accordions.scss
//

.custom-accordion {
    .accordion-item {
        box-shadow: none;
    }

    .accordion-header {
        background-color: #{map-get($grays, "100")};
        border-bottom: none;
    }

    .accordion-body {
        border: #{($border-width) solid map-get($grays, "100")};
        &.collapsing {
            padding: 0 !important;
        }
    }

    .accordion-arrow {
        font-size: 1.2rem;
        position: absolute;
        right: 0;
    }

    .accordion-button {
        padding: 0.75rem 1.5rem !important;
        &:after {
            background-image: none;
        }
        &:not(.collapsed) {
            .accordion-arrow {
                display: block;
            }
        }
    }
    a {
        &.collapsed {
            i.accordion-arrow {
                &:before {
                    content: "\F142";
                }
            }
        }
    }
}

.custom-accordion-title {
    color: $custom-accordion-title-color;
    position: relative;

    &:hover {
        color: #{lighten(map-get($grays, "900"), 7.5%)};
    }
}

.accordion {
    > .card {
        > .card-header {
            border-radius: 0;
            margin-bottom: -1px;
        }
    }
}

.accordion {
    .accordion-item {
        border: none;
        box-shadow: $box-shadow;
        .accordion-header {
            border-radius: 0;
            border-bottom: 1px solid #{map-get($grays, "100")};
            margin-bottom: -1px;
            .accordion-button {
                padding: 0.75rem 1.5rem !important;
                box-shadow: none !important;
                &:after {
                    background-image: none;
                }
                &:not(.collapsed) {
                    color: inherit;
                    background-color: inherit;
                }
            }
        }
        .accordion-body {
            padding: 0 !important;
        }
    }
}

// Dark mode
body[data-layout-color="dark"] {
    .custom-accordion {
        .accordion-header {
            background-color: #{map-get($dark-grays, "100")};
        }

        .accordion-body {
            border: #{($border-width) solid map-get($dark-grays, "100")};
        }
    }

    .custom-accordion-title {
        &:hover {
            color: #{lighten(map-get($dark-grays, "900"), 7.5%)};
        }
    }

    .accordion {
        .accordion-item {
            .accordion-header {
                border-bottom: 1px solid #{map-get($dark-grays, "100")};
            }
        }
    }
}
