//
// dropzone.scss
//

.dropzone {
    border: 2px dashed $input-border-color !important;
    background: $input-bg !important;
    border-radius: 6px !important;
    cursor: pointer;
    min-height: 150px;
    padding: 20px;

    ngx-dropzone-label {
        z-index: 9 !important;
        color: $body-color !important;
    }

    .dz-message {
        text-align: center;
        margin: 2rem 0;
    }
    &.dz-started {
        .dz-message {
            display: none;
        }
    }
}

body[data-layout-color="dark"] {
    .dropzone {
        ngx-dropzone-label {
            color: var(--#{$variable-prefix}body-color) !important;
        }
    }
}
