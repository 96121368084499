//
// Select 2
//

.select2-container {
    width: 100% !important;

    .select2-selection--single {
        border: $input-border-width solid $input-border-color !important;
        height: $input-height !important;
        background-color: $input-bg !important;
        outline: none;
        .select2-selection__rendered {
            line-height: 36px !important;
            padding-left: 12px !important;
            color: $input-color !important;
        }
        .select2-selection__arrow {
            height: 34px !important;
            width: 34px !important;
            /*rtl:ignore*/
            right: 3px !important;
            &::before {
                border-color: #{map-get($grays, "400")} transparent transparent transparent !important;
                border-width: 6px 6px 0 6px !important;
            }
        }
    }
}

.select2-container--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent #{map-get($grays, "400")} transparent !important;
                border-width: 0 6px 6px 6px !important;
            }
        }
    }
}

.select2-results__option {
    padding: 6px 12px;
    text-align: left;
    color: #{map-get($grays, "700")} !important;
    &.select2-results__option--highlighted {
        color: $white !important;
    }
    .select2-results__group {
        color: var(--#{$variable-prefix}body-color) !important;
    }
}

.select2-dropdown {
    border: $dropdown-border-width solid $dropdown-border-color !important;
    box-shadow: $box-shadow !important;
    background-color: $dropdown-bg !important;
    z-index: $zindex-modal + 1 !important;
}

.select2-container--default {
    .select2-results__option--selected {
        background-color: #{map-get($grays, "200")} !important;
    }
    .select2-selection__rendered {
        .select2-selection__placeholder {
            color: $input-color !important;
        }
    }
    .select2-search--dropdown {
        padding: 10px;
        background-color: $dropdown-bg !important;
        .select2-search__field {
            outline: none;
            border: 1px solid $input-border-color !important;
            background-color: $input-bg !important;
            color: $input-color !important;
        }
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: $primary !important;
    }
    .select2-results__option[aria-selected="true"] {
        background-color: $dropdown-bg !important;
        color: $dropdown-link-active-color !important;
        &:hover {
            background-color: $primary !important;
            color: $white !important;
        }
    }
    .select2-selection--multiple {
        .select2-selection__choice__display {
            padding-left: 36px !important;
            padding-right: 5px !important;
        }
    }
    .select2-selection--single {
        .select2-selection__arrow {
            /*rtl:ignore*/
            right: 1px;
            /*rtl:ignore*/
            left: auto;
        }
    }
}

.select2-container {
    // padding: 1px 4px !important;
    .select2-selection--multiple {
        min-height: $input-height !important;
        border: $input-border-width solid $input-border-color !important;
        background-color: $input-bg !important;
        padding-bottom: 0px !important;
        // line-height: 1.2;
        .select2-search__field {
            border: 0 !important;
            color: $input-color !important;
        }
        .select2-selection__rendered {
            padding: 0px 12px !important;
            .select2-selection__placeholder {
                margin-top: 7px !important;
            }
        }
        .select2-selection__choice {
            background-color: $primary !important;
            border: none !important;
            color: $white !important;
            border-radius: 3px !important;
            padding: 0px 7px 0 0 !important;
            margin-top: 6px !important;

            span:last-child {
                padding-left: 36px !important;
                padding-right: 5px !important;
            }
        }
        .select2-selection__choice__remove {
            color: $white !important;
            margin-right: 7px !important;
            border-color: tint-color($primary, 10%) !important;
            padding: 0 8px !important;
            // position: relative;
            &:hover {
                color: $white !important;
                background-color: $primary !important;
            }
        }
    }
    .select2-search--inline {
        .select2-search__field {
            margin-top: 7px !important;
        }
    }
    .select2-search {
        textarea {
            &::placeholder {
                color: $input-placeholder-color !important;
            }
        }
    }
}

[dir="rtl"] .select2-container--open .select2-dropdown {
    left: auto;
    right: 0;
}

// Dark mode
body[data-layout-color="dark"] {
    .select2-container {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-color: #{map-get($dark-grays, "400")} transparent transparent transparent !important;
                }
            }
        }
    }
    .select2-results__option {
        color: #{map-get($dark-grays, "700")} !important;
        .select2-results__group {
            color: var(--ct-body-color) !important;
        }
    }
    .select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-color: transparent transparent #{map-get($dark-grays, "400")} transparent !important;
                }
            }
        }
    }

    .select2-container--default {
        .select2-results__option--selected {
            background-color: #{map-get($dark-grays, "300")} !important;
        }
    }
}
